import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { __tr, __select, __selectAll, __el } from '@modules';
import { scrub, withAnimation } from '@core';
import { getBreakpoint } from '@themed';

interface AnimateWorkshopPackagesProps {
  triggerRef?: () => React.RefObject<HTMLDivElement>;
}
interface CreatePackagesScrollerProps extends AnimateWorkshopPackagesProps {
  isMobile?: boolean;
}

const animateStaggeredPackages = () => {
  const tl = gsap.timeline();

  const packages = document.querySelectorAll('.package');

  packages.forEach((pkg, i) => {
    const leftPosition = (100 / packages.length) * i;

    tl.set(pkg, {
      marginTop: `-${20 * i + 1}rem`,
      position: 'absolute',
      left: `${leftPosition}%`,
      zIndex: i + 1,
    });
  });

  tl.to(packages, {
    marginTop: 0,
    ease: 'expoScale(0.5,7,none)',
    stagger: {
      amount: 1,
      from: 'start',
    },
    duration: 2,
  });

  return tl;
};

const createPackagesScroller = ({ triggerRef, isMobile }: CreatePackagesScrollerProps) => {
  const trigger = __tr(triggerRef);

  if (!trigger) return;

  if (isMobile) {
    const container = __el(triggerRef);
    const packageContainer = document.querySelector('.package-container');
    const packages = __selectAll('.package', trigger);
    const packageWidth = packages[0].clientWidth;
    const tl = gsap.timeline();

    tl.set(packageContainer, {
      marginLeft: `${packageWidth * 3}px`,
    });

    ScrollTrigger.create({
      trigger: container,
      // markers: process.env.NODE_ENV === 'development',
      start: 'top top',
      end: () => `+=${packageWidth * packages.length - 1}`,
      snap: 1 / (packages.length - 1),
      scrub: 1,
      pin: true,
      // invalidateOnRefresh: true,
      refreshPriority: 5,
      animation: gsap.timeline()
        .to(
          packages,
          { xPercent: -100 * (packages.length - 1), ease: 'linear' },
        ),
    });

    return;
  }

  ScrollTrigger.create({
    refreshPriority: 1,
    trigger,
    ...scrub(),
    // markers: process.env.NODE_ENV === 'development',
    start: '+=10% bottom',
    end: '-=10% top',
    animation: animateStaggeredPackages(),
  });
};

export const animatePackages = (props: AnimateWorkshopPackagesProps) => {
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.saveStyles([__select('[aria-label]')]);
  ScrollTrigger.matchMedia({
    [`(min-width: ${getBreakpoint(1)})`]: () => createPackagesScroller({ ...props, isMobile: false }),
    [`(max-width: ${getBreakpoint(1)})`]: () => createPackagesScroller({ ...props, isMobile: true }),
  });
};

export const useAnimateWorkshopPackages = (props: AnimateWorkshopPackagesProps) => withAnimation<AnimateWorkshopPackagesProps>(animatePackages, props);
