import React from 'react';

import { ContactSection, FinnoscoreBanner } from '@composite';
import { PureSection, SectionWithContent, SvgIcon, ThemedIcon } from '@core';
import { Flex } from '@themed';
import { __ } from '@modules';
import { StyledTestimonals } from '../Testimonals';
import Divider from '../casestudies/Divider';
import { FinnoscoreWorkshop } from './FinnoscoreWorkshop';
import { testimonialStyles } from './Testimonials.styles';
import { styles } from './index.styles';
import { FinnoscoreWorkshopPackages } from './FinnoscoreWorkshopPackages';
import { WorkshopExperts } from './WorkshopExperts';
import Init from './Init';

const dividerStyles = {
  minHeight: 'unset',
  height: 'max-content',
  width: '100%',
  paddingLeft: [0, '5rem'],
  '& > div > *': {
    maxWidth: '50rem',
    fontSize: '18px',
  },
};

export const WorkshopPage = () => {
  const testimonialRef = React.useRef(null);
  const servicesRef = React.useRef(null);
  const finnoWorkshopRef = React.useRef(null);

  return (
    <React.Fragment>
      <PureSection sx={styles}>
        <FinnoscoreBanner />
        <Init />
      </PureSection>

      <Divider text={__('pages.workshops.packages.divider')} sx={dividerStyles} />

      <FinnoscoreWorkshopPackages />

      <SectionWithContent id="workshops-workshop-types" sx={{ zIndex: 111, position: 'relative', bg: 'primary' }} ref={finnoWorkshopRef}>
        <FinnoscoreWorkshop triggerRef={() => finnoWorkshopRef} />
      </SectionWithContent>

      <WorkshopExperts />

      <SectionWithContent id="workshops-testimonials" ref={testimonialRef} variant="styles.layoutMaxWidth" sx={styles.testimonials.section}>
        <Flex sx={styles.testimonials.content} variant="flex.colCenter">
          <StyledTestimonals styles={testimonialStyles} />
        </Flex>
      </SectionWithContent>

      <ContactSection id="finnoscore.contact" triggerRef={() => servicesRef}>
        <ThemedIcon sx={{ variant: 'images.pureCircleIcon' }}><SvgIcon name="rocket" /></ThemedIcon>
      </ContactSection>
    </React.Fragment>
  );
};
