import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    height: '36rem',
    width: '36rem',
    backgroundColor: 'primary',
    borderRadius: 999,
    overflow: 'hidden',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',
    marginLeft: [0, 0, -9, 0],
  } as SxStyleProp,

  offer: {
    flexDirection: ['column', 'column', 'row'],
    marginLeft: ['0rem', '0rem', '6rem'],
    width: ['70%', '80%', '100%'],
    h2: {
      textAlign: ['center', 'left'],
    },
    svg: {
      position: ['static', 'static', 'absolute'],
      left: 8,
      top: 9,
      height: 8,
    },
  },
};
