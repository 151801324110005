import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    position: 'relative',
    bg: 'muted',
    height: ['unset', 'unset', '80vh !important'],
    minHeight: ['unset', 'unset', '80vh !important'],
  } as SxStyleProp,
  content: {
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    alignItems: 'center',
  } as SxStyleProp,
  packageRow: {
    backgroundColor: 'muted',
    width: ['none', 'none', '100%'],
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'relative',
  } as SxStyleProp,
};

export const calendlyStyles = {
  width: 'max-content',
  margin: '0 auto',
  position: 'absolute',
  bottom: '50px',
  zIndex: 999,
} as SxStyleProp;

export const mobilePackageSize = '23rem';
export const tabletPackageSize = '20rem';
export const desktopPackageSize = '23rem';

export const packageStyles = {
  container: {
    bg: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    width: [mobilePackageSize, mobilePackageSize, tabletPackageSize, desktopPackageSize],

    padding: '2rem',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  } as SxStyleProp,

  button: {
    backgroundColor: 'primary',
    color: 'background',
    width: '2rem',
    height: '2rem',
    borderRadius: 999,
  },

  title: {
    fontWeight: 'bold',
    fontSize: ['m', 'm', 'xs'],
  },

  description: {
    fontSize: ['xxs', 'xs'],
    textAlign: 'center',
    width: '85%',
  } as SxStyleProp,

  price: {},

  details: {
    li: {
      fontSize: '1rem',
      my: 3,
    },
  },
};
