import React from 'react';
import {
  SectionWithContent, Title, Box, Text, Flex, ImageLoader, SvgIcon, Grid,
} from '@core';
import { __ } from '@modules';
import { styles } from './WorkshopExperts.styles';

export function WorkshopExperts() {
  return (
    <SectionWithContent sx={styles.section}>
      <Grid sx={styles.sectionContent}>
        <Box>
          <Title sx={styles.title}>{__('pages.workshops.experts.title')}</Title>
        </Box>
        <Flex sx={styles.founders}>
          <Box sx={styles.imageOverlay}>
            <ImageLoader src="chris.jpg" alt="" />
          </Box>

          <Box sx={styles.iconContainer}>
            <SvgIcon sx={styles.circleArrow} name="circle-arrow" />
            <SvgIcon sx={styles.threeHundredSixty} color="primary" name="360" />
          </Box>

          <Box sx={styles.imageOverlay}>
            <ImageLoader src="martin.jpg" alt="" />
          </Box>
        </Flex>
        <Box>
          <Text sx={{ fontSize: ['xs'] }}>
            {__('pages.workshops.experts.description')}
          </Text>
        </Box>
      </Grid>
    </SectionWithContent>
  );
}
