import { SxStyleProp } from 'theme-ui';

export const styles = {
  section: {
    padding: 6,
    bg: 'background',
  },
  sectionContent: {
    display: 'grid',
    gridTemplateRows: ['max-content 1fr max-content', '1fr 1fr 1fr'],
    placeItems: 'center stretch',
  },
  title: {
    color: 'primary',
  },
  imageOverlay: {
    borderRadius: 'fullSize',
    width: [10, '10rem', '13rem', '16rem'],
    height: [10, '10rem', '13rem', '16rem'],
    overflow: 'hidden',
  },
  founders: {
    height: ['50rem', '100%'],
    flexDirection: ['column', 'row'],
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  iconContainer: {
    position: 'relative',
  } as SxStyleProp,

  circleArrow: {
    position: 'absolute',
    width: ['20rem', '12rem', '15rem', '20rem'],
    height: 'auto',
    transform: 'translate(-50%,-50%)',
    left: '50%',
    top: '50%',
  } as SxStyleProp,

  threeHundredSixty: {
    position: 'absolute',
    width: ['5rem', '3rem', '4rem', '5rem'],
    height: 'auto',
    transform: 'translate(-50%,-50%)',
    left: '50%',
    top: '50%',
  } as SxStyleProp,
};
