import React from 'react';
import { Box, Button, Flex, Text } from 'theme-ui';
import { __ } from '@modules';
import { Paragraph } from '@themed';
import { CalendlyButton, SectionWithContent } from '@core';
import { useAnimateWorkshopPackages } from './animateWorkshopPackages';
import { useAnimatePackage } from './animateWorkshopPackageElement';
import { calendlyStyles, styles, packageStyles } from './FinnoscoreWorkshopPackages.styles';

export const FinnoscoreWorkshopPackages = () => {
  const finnoWorkshopPackagesRef = React.useRef(null);

  useAnimateWorkshopPackages({
    triggerRef: () => finnoWorkshopPackagesRef,
  });

  return (
    <SectionWithContent
      id="workshops-packages"
      sx={styles.container}
      ref={finnoWorkshopPackagesRef}
    >
      <Flex sx={styles.content}>
        <Flex className="package-container" sx={styles.packageRow}>
          {Array.from({ length: 4 }).map((_, i) => (
            <Package key={i} index={i} />
          ))}
        </Flex>
        <CalendlyButton
          href={__('pages.aboutus.founders.list.0.calendar')}
          sx={calendlyStyles}
          title={__('pages.workshops.packages.button.text')}
        />
      </Flex>
    </SectionWithContent>
  );
};

type PackageProps = {
  index: number;
}

function Package({ index }: PackageProps) {
  const packageRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  useAnimatePackage(packageRef, isOpen);

  const pageId = `pages.workshops.packages.items.${index}`;

  return (
    <Box className="package" ref={packageRef} sx={packageStyles.container}>
      <Text sx={packageStyles.title}>{__(`${pageId}.title`)}</Text>
      <Paragraph sx={packageStyles.description}>{__(`${pageId}.description`)}</Paragraph>
      <Paragraph sx={packageStyles.price}>{__(`${pageId}.price`)}</Paragraph>
      {
        isOpen
          ? <Button sx={packageStyles.button} onClick={() => setIsOpen(false)}>-</Button>
          : <Button sx={packageStyles.button} onClick={() => setIsOpen(true)}>+</Button>
      }
      {isOpen && (
        <Paragraph sx={packageStyles.details} aria-label="details">{__(`${pageId}.details`)}</Paragraph>
      )}
    </Box>
  );
}
