/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';
import React from 'react';
import { Flex, SvgIcon, CompactSectionContentStyles, CompactSectionContent } from '@core';
import { styles } from './WorkshopOffer.styles';

interface FinnoscoreWorkshopOffersProps extends SxProps {
  title: string;
  id: number;
  icon: string;
}

const WorkshopOffer = React.forwardRef<HTMLDivElement, FinnoscoreWorkshopOffersProps>(({
  title, id, icon, sx,
}, ref) => (
  <Flex sx={{ ...styles.offerContainer, ...sx }} ref={ref} className="listItem2" data-cy="workshop-types-offer">
    <SvgIcon name={icon} color="text" sx={id === 0 ? styles.rocket : styles.eye} />
    <CompactSectionContentStyles data-cy="compact-section-styles" key={`workshops.finnoworkshops.${title}`} sx={{ ...styles.content }}>
      <CompactSectionContent id={`workshops.finnoworkshops.${title}`} buttonVariant="ctaInvert" />
    </CompactSectionContentStyles>
  </Flex>
));

export default WorkshopOffer;
