export const styles = {
  offerContainer: {
    display: 'flex',
    width: 'fullSize',
    flexDirection: ['column', 'row', 'row', 'row'],
    justifyContent: ['center', 'center', 'flex-start', 'flex-start'],
    alignItems: ['center', 'center', 'flex-start', 'flex-start'],
    mt: 5,
  },
  rocket: {
    '#rocket_svg__Path1, #rocket_svg__Path3': {
      fill: 'background',
    },
  },
  eye: {
    '#eye_svg__Combined-Shape': {
      fill: 'background',
    },
  },
  content: {
    ml: [0, 4, 6, 8],
    h2: {
      color: 'background',
      fontSize: 'm',
    },
    ul: {
      height: ['100%', '15rem'],
      variant: 'lists.whiteList',
      maxWidth: ['100%', '100%', '100%', '75%'],
    },
  },
};
