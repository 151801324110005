import React from 'react';
import gsap from 'gsap';
import { __select, __tr } from '@modules';
import { getBreakpoint } from '@themed';
import { desktopPackageSize, mobilePackageSize, tabletPackageSize } from './FinnoscoreWorkshopPackages.styles';

const mm = gsap.matchMedia();

export type MatchMediaConditions = {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

type AnimationProps = {
  tl: gsap.core.Timeline;
  packageRef: () => React.RefObject<HTMLDivElement>;
  isOpen: boolean;
};

export const animatePackage = ({ tl, packageRef, isOpen }: AnimationProps) => {
  const packageElement = __tr(packageRef);
  if (!packageElement) return;

  const detail = __select('[aria-label="details"]', packageElement);

  mm.add({
    isDesktop: `(min-width: ${getBreakpoint(2)})`,
    isTablet: `(max-width: ${getBreakpoint(2)})`,
    isMobile: `(max-width: ${getBreakpoint(1)})`,
  }, (context) => {
    const { isMobile, isTablet } = context.conditions as MatchMediaConditions;

    function getHeightSize() {
      if (isMobile) {
        return mobilePackageSize;
      }
      if (isTablet) return tabletPackageSize;
      return desktopPackageSize;
    }

    tl
      .fromTo(
        packageElement,
        {
          marginTop: 0,
          height: getHeightSize(),
          borderRadius: '100%',
          justifyContent: 'center',
        },
        {
          marginTop: isMobile ? '-5rem' : 0,
          height: '43rem',
          borderRadius: '30px',
          justifyContent: 'start',
          ease: 'power4.out',
          duration: 0.5,
        },
      )
      .fromTo(detail, { opacity: 0 }, { opacity: 1 }, '-=0.2');
  });

  if (isOpen) {
    tl.play();
  } else {
    tl.reverse();
  }
};

export const useAnimatePackage = (packageRef: React.MutableRefObject<null>, isOpen: boolean) => {
  React.useEffect(() => {
    const tl = gsap.timeline({ paused: true, reversed: !isOpen });
    if (packageRef.current) {
      animatePackage({
        tl,
        packageRef: () => packageRef,
        isOpen,
      });
    }
    return () => {
      tl.kill();
    };
  }, [isOpen]);
};
