import React from 'react';
import { Box, CalendlyButton, CompactSection } from '@core';
import { __ } from '@modules';
import WorkshopOffer from './WorkshopOffer';
// import { FinniRevealCaseTeasers } from '../casestudies';
import { styles } from './Init.styles';

export default function Init() {
  return (
    <CompactSection
      titleAs="h1"
      id="workshops.init"
      contentBottom={(
        <CalendlyButton
          href={__('pages.aboutus.founders.list.0.calendar')}
          sx={{}}
          title={__('pages.workshops.packages.button.text')}
        />
      )}
    >
      {/* <FinniRevealCaseTeasers /> */}
      <InitOffer />
    </CompactSection>
  );
}

function InitOffer() {
  return (
    <Box sx={styles.container}>
      <WorkshopOffer
        sx={styles.offer}
        id={1}
        title="discovery"
        icon="eye"
      />
    </Box>
  );
}
